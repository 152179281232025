<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="search">
          <div class="row">
            <div class="col-lg-2 col-md-6">
              <Datepicker
                v-model="filter.startDate"
                id="kpi_nmh_filter_from"
                :label="$t('kpiNmh.date_from')"
                type="month"
                dateFormat="Y-m"
                class="m-b-0"
                :min-date="new Date('2023-12-31')"
              />
            </div>
            <div class="col-lg-2 col-md-6">
              <Datepicker
                v-model="filter.endDate"
                id="kpi_nmh_filter_to"
                :label="$t('kpiNmh.date_to')"
                type="month"
                dateFormat="Y-m"
                class="m-b-0"
                :min-date="new Date('2023-12-31')"
              />
            </div>
            <div class="col-lg-1">
              <button class="btn btn-success m-t-20">{{ $t('buttons.search') }}</button>
            </div>
            <div class="col-lg-7 data-hub-info">
              <strong class="">{{ `Zdroj: DataHUB` }}</strong>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import Datepicker from '@/components/form/Datepicker'

export default {
  name: 'KpiNmhFilter',
  components: {
    Datepicker
  },
  computed: {
    filter () {
      return this.$store.getters['kpiNmhDataHub/filter']
    }
  },
  methods: {
    search () {
      this.$emit('search')
    }
  }
}
</script>

<style lang="scss" scoped>
.data-hub-info {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
</style>
